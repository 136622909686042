import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../Components/Navbar/Index';
import Sidebar from '../Components/SideBar/Index';
import Hero from '../Components/Hero/Index';
import InfoSection from '../Components/Info/Index';
import Code from '../Components/codeblock/Code';
import * as ChainIcons from "@thirdweb-dev/chain-icons";
import { homeObj1, homeObj2, homeObj3, homeObj4, homeObj5 , homeObj6 , 
  homeObj7 , homeObj8 , homeObj9 , homeObj10 , homeObj11, contact } from '../Components/Info/Data';
import Footer from '../Components/Footer/Index';
import { setupAnimations } from '../animate';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHorizontalScroll, setIsHorizontalScroll] = useState(false);
  const [activePageIndex, setActivePageIndex] = useState(0);
  const horRef = useRef(null);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = (e) => {
    const horElement = horRef.current;

    if (horElement && horElement.contains(e.target)) {
      const delta = Math.max(-1, Math.min(1, e.deltaY));
      const scrollAmount = horElement.scrollWidth/3.5 * delta; // Adjust the scroll amount as needed
      horElement.scrollLeft += scrollAmount;
      console.log(horElement.scrollWidth / 1.5, horElement.scrollLeft);
      if (horElement.scrollLeft < 33 && delta < 0) {
        console.log('left');
      } else if (horElement.scrollLeft > horElement.scrollWidth / 1.5 && delta > 0) {
        console.log('right');
      } else {
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', handleScroll, { passive: false });
    setupAnimations();
    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, []);

  useEffect(() => {
    const horElement = horRef.current;

    const handleHorizontalScroll = () => {
      const scrollLeft = horElement.scrollLeft;
      const isScrolling = scrollLeft > 0 && scrollLeft < horElement.scrollWidth - horElement.clientWidth;
      setIsHorizontalScroll(isScrolling);
      setActivePageIndex(Math.floor(scrollLeft / horElement.clientWidth));
    };

    horElement.addEventListener('scroll', handleHorizontalScroll);
    return () => {
      horElement.removeEventListener('scroll', handleHorizontalScroll);
    };
  }, []);

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Hero id="home-section" />
      <InfoSection id="discover-section" {...homeObj4} />
      <InfoSection id="about-section" {...homeObj1} />
      <InfoSection id="discover-section" {...homeObj2} />
      <InfoSection id="discover-section" {...homeObj3} />
     
      <InfoSection id="discover-section" {...homeObj5} />
      <div className={`hor ${isHorizontalScroll ? 'scroll-horizontal' : ''}`} ref={horRef}>
        <div className="hor-content">
          <div className="hor-section">
          <InfoSection id="how-section" {...homeObj7} />
          </div>
          <div className="hor-section">
          <InfoSection id="how-section" {...homeObj8} />
          </div>
          <div className="hor-section">
      
          <InfoSection id="how-section" {...homeObj9} />
          </div>
          <div className="hor-section">
      
      <InfoSection id="how-section" {...homeObj10} />
      </div>
      <div className="hor-section">
      
      <InfoSection id="how-section" {...homeObj11} />
      </div>
        </div>
        <div className="pagination">
        {[...Array(5)].map((_, index) => (
          <div
            key={index}
            className={`pagination-item ${activePageIndex === index ? 'active' : ''}`}
            onClick={() => {
              horRef.current.scrollLeft = index * horRef.current.clientWidth;
            }}
          ></div>
        ))}
      </div>
      </div>
      <InfoSection id="discover-section" {...contact} />
     

   



      <Footer />
    </>
  );
};

export default Home;
