import React from 'react';
import CodeBlock from '../codeblock/CodeBlock';

function Code() {
  const code = `curl -X POST -H "Content-Type: application/json" 
  -d '{"template": "your_template_id"}' 
  https://api.relayer.yourplatform.co/create-account`;

  return <CodeBlock title="Create Multichain Account" code={code} />;
}

export default Code;
