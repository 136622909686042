import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import {
    HeroContainer,
    HeroBg,
    VideoBg,
    HeroContent,
    HeroP,
    HeroBtnWrapper,
    LogoContainer,
} from '../Hero/HeroElements';

Modal.setAppElement('#root'); // Required for accessibility

const Hero = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [videoSource, setVideoSource] = useState("");

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    useEffect(() => {
        const getInternetSpeed = () => {
            setTimeout(() => {
                const internetSpeed = 1; // Placeholder for internet speed measurement
                const threshold = 2; // Define your threshold for slow internet speed
                const VideoCompressed = "/videos/background.mp4";
                const Video = "/videos/background.mp4";
                setVideoSource(internetSpeed < threshold ? VideoCompressed : Video);
            }, 2000); // Simulating a delay of 2 seconds for the internet speed measurement
        };

        getInternetSpeed();
    }, []);

    return (
        <>
            <HeroContainer>
                <HeroBg>
                    <VideoBg autoPlay loop muted src={videoSource} type='video/mp4' />
                </HeroBg>
                <HeroContent>
                    <LogoContainer>
                        <img src="/logo.png" style={{ "max-width": "70vw" }} />
                    </LogoContainer>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <HeroP>
                            Making Web3 accessible
                        </HeroP>
                        <HeroBtnWrapper>
                            <button 
                                onClick={openModal} 
                                style={{ 
                                    padding: '8px 16px', 
                                    cursor: 'pointer', 
                                    fontSize: '14px', 
                                    borderRadius: '5px', 
                                    backgroundColor: 'transparent', 
                                    color: '#fff', 
                                    border: '1px solid #fff', 
                                    marginTop: '20px' // Add margin to move it further down from the header
                                }}
                            >
                                Watch Demo Video
                            </button>
                        </HeroBtnWrapper>
                    </div>
                </HeroContent>
            </HeroContainer>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Demo Video"
                style={{
                    overlay: {
                        zIndex: 1000, // Increase z-index to ensure the modal overlay appears on top
                        backgroundColor: 'rgba(0, 0, 0, 0.75)', // Slightly darken the background
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxWidth: '600px',
                        padding: '0',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        zIndex: 1001, // Ensure the modal content itself has a high z-index
                    },
                }}
            >
                <div style={{ padding: '20px', textAlign: 'right' }}>
                    <button onClick={closeModal} style={{ background: 'none', border: 'none', fontSize: '1.5rem', cursor: 'pointer' }}>&times;</button>
                </div>
                <div style={{ padding: '0 20px 20px 20px' }}>
                    <iframe
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/QgPAoIKN4uM"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </Modal>
        </>
    );
};

export default Hero;
