import React from 'react';
import Lottie from 'lottie-react';
import multichain from '../Components/Lottie/multichain.json';

import zero from '../Components/Lottie/zero.json';
import secure from '../Components/Lottie/secure.json';
import pba from '../Components/Lottie/pba.json';
 

const loss = (name) => {
  switch (name) {
    case 'multichain':
      return multichain;
    case 'zero':
      return zero;
    case 'secure':
      return secure;
    case 'pba':
      return pba;
    default:
      return multichain; // Default to multichain if no match is found
  }
}

const MyLottieAnimation = ({ name }) => {
  return (
    <div>
      <Lottie animationData={loss(name)} style={{ width: 300, height: 300 }} />
    </div>
  );
};
export default MyLottieAnimation;
