import React from 'react';
import Code from '../codeblock/Code';
import Form from '../form/Form';
import Multichain from '../Multichain/Multichain';

import MyLottieAnimation from '../Lottie';
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  BtnWrap,
  Column1,
  Column2,
  Heading,
  ImgWrap,
  Subtitle,
  TextWrapper,
  TopLine,
  Img
} from '../Info/InfoElements';

import { Button } from '../../Utilities/ButtonElements';

const InfoSection = ({
  lightBg,
  imgStart, prebutton,
  topLine,
  lightText,
  darkText,
  headline,
  description,
  url,
  buttonLabel, 
  alt,
  img,
  loss,
  id,
  primary,
  dark,
  dark2
}) => {

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id} >
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
         <Column1>
  <TextWrapper className={`headline ${lightText ? 'light animate-on-scroll' : 'animate-on-scroll'}`}>
    <TopLine>{topLine.split('\n').map((line, index) => <React.Fragment key={index}>{line}<br /></React.Fragment>)}</TopLine>
    <Heading lightText={lightText}>{headline.split('\n').map((line, index) => <React.Fragment key={index}>{line}<br /></React.Fragment>)}</Heading>
    <Subtitle darkText={darkText} style={{ marginBottom: '1.5rem' }}>
      {description.split('\n').map((paragraph, index) => (
        <React.Fragment key={index}>
          {paragraph}
          <br />
        </React.Fragment>
      ))}
    </Subtitle>
    {prebutton === true && (<Multichain/>)}
    <BtnWrap>

    {id === 'contact' ? (
            <iframe
            src="https://site.adappter.xyz/"
            style={{ width: "100%", minHeight: "600px", border: "none", overflow: "hidden" }}
            scrolling="no"
          />
          
                ):(<Button
        to={url}
        smooth={true}
        duration={500}
        exact={true}
        primary={primary ? 1 : 0}
        dark={dark ? 1 : 0}
        dark2={dark2 ? 1 : 0}
      >
        {buttonLabel}
      </Button>)}
    </BtnWrap>
  </TextWrapper>
</Column1>


            <Column2>
              <ImgWrap>
                {id !== 'ease'  && loss && (
                //  <Img src={img} alt={alt} /> // Use the default image if id does not match any specific diagram
                <MyLottieAnimation name={loss} />
                )}
                {id !== 'ease'  && !loss && (
                  <Img src={img} alt={alt} /> // Use the default image if id does not match any specific diagram
               
                )}
                {id === 'ease' && (
                 <Code/>
                )}
                
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
