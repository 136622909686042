import React, { useEffect, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { Button } from '../../Utilities/ButtonElements'
import { animateScroll as scroll } from 'react-scroll';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink
} from './NavElements';

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [connected, setConnected] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  const handleConnectWallet = async () => {
    try {
      if (typeof window.unisat !== 'undefined') {
        // Unisat wallet extension is installed
        if (connected) {
          await window.unisat.disconnect(); // Disconnect the wallet
          setConnected(false); // Update connected state to false
          setScrollNav(false); // Reset scrollNav state
        } else {
          const accounts = await window.unisat.requestAccounts();
          handleAccountsChanged(accounts);
        }
      } else {
        // Unisat wallet extension is not installed
        window.location.href = 'https://unisat.io/download'; // Replace with the actual download website URL
      }
    } catch (error) {
      console.log('Error connecting/disconnecting wallet:', error);
    }
  };

  const handleAccountsChanged = (accounts) => {
    if (accounts.length > 0) {
      setConnected(true);
    } else {
      setConnected(false);
      setScrollNav(false); // Reset scrollNav state
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
    return () => {
      window.removeEventListener('scroll', changeNav);
    };
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
         
          <NavBtn>
                <Button to="https://app.adappter.xyz">Enter App</Button>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
