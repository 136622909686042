export const homeObj1 = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Multi-chain Operations',
  headline: 'Seamlessly plug into a multichain universe',
  description: 'As the future becomes multichain, manage and transfer assets across different networks with ease. Experience smooth cross-chain interoperability and seamless asset management.',
  url: 'https://app.adappter.xyz',
  buttonLabel: 'Get Started',
  imgStart: false,
  prebutton: true,
  img: '/images/2.svg',
  loss:'multichain',
  alt: 'about',
  dark: false,
  primary: true,
  darkText: false
};




export const homeObj2 = {
  id: 'inter',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Regulatory Agile",
  headline: "Control scope of onchain activities offered to your users",
  description: "Implement purpose-bound accounts to control and tailor the scope of activities for your clients. Provide precise onchain solutions while staying agile and compliant with evolving regulatory requirements.",
  url: 'https://litepaper.adappter.xyz/purpose-bound-account',
  buttonLabel: 'Learn More',
  imgStart: true,
  prebutton: false,
  img: '/images/Account2.svg',
  alt: 'Piggybank',
  loss:'pba',
  dark: false,
  primary: true,
  darkText: false
};


export const homeObj4 = {
  id: 'inter',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Zero Learning Curve",
  headline: "Onchain activities handling abstracted away from your users ",
  description: "Hide the complexities of onchain activities from your users. Eliminate concerns about wallet management, private keys, and asset transfer risks, ensuring a smooth and intuitive user experience.",
  url: 'https://app.adappter.xyz',
  buttonLabel: 'View Demo',
  imgStart: true,
 prebutton: false,
  img: '/images/1.svg',
  alt: 'Piggybank',
  loss:'zero',
  dark: false,
  primary: true,
  darkText: false
};

export const homeObj5 = {
  id: 'ease',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Ease Web 3 Integration',
  headline: 'Plug your solution into the world of web3 in 3 lines of codes',
  url: 'https://one.relayer.adappter.xyz/',
  description: 'Integrate your solution into the Web3 ecosystem with just three lines of code. Adappter enables both new and established businesses to enter the crypto space with minimal product changes. Leverage Adappter to unlock the full potential of your solutions.',
  buttonLabel: 'See demo',
  imgStart: false,
 prebutton: false,
  img: '/images/img2.svg',
  alt: 'Paper',
  dark: false,
  primary: true,
  darkText: false
};

export const homeObj3 = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Enhanced Security & UX',
  headline: 'Customized authentication methods',
  description: 'Enhance security and user experience by integrating your current authentication methods for message signing. Maintain high security standards, and add extra layers with 2FA or custom methods tailored to your needs.',
  url: 'https://litepaper.adappter.xyz/process/authentication',
  buttonLabel: 'Learn More',
  imgStart: false,
 prebutton: false,
  img: '/images/Account.svg',
  loss: '/images/Account.svg',
  loss:'secure',
  alt: 'about',
  dark: false,
  primary: true,
  darkText: false
};

export const homeObj6 = {
  id: 'join',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Join our Community',
  headline: 'Stay Connected on Twitter',
  url: 'https://twitter.com/adappter',
  description: 'Follow us on Twitter to stay updated with the latest news, announcements, and developments.',
  buttonLabel: 'Follow Us',
  imgStart: false,
 prebutton: false,
  img: '/images/Account2.svg',
  alt: 'Paper',
  dark: false,
  primary: false,
  darkText: false
};

export const homeObj7 = {
  id: 'arc',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'How does it work?',
  headline: 'Define Contract Template',
  url: 'https://litepaper.adappter.xyz/process',
  description: `Organize your customer groups based on unique business needs, membership tiers, or legal jurisdictions. Design smart contract templates tailored to their exposure across different chains and the specific activities allowed for each user group.`,
  buttonLabel: 'Learn More',
  imgStart: false,
 prebutton: false,
  img: '/images/Account2.svg',
  alt: 'Paper',
  dark: false,
  primary: false,
  darkText: false
};

export const homeObj8 = {
  id: 'ibc',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'How it Works?',
  headline: 'Create multichain presence for users via API calls',
  url: 'https://litepaper.adappter.xyz/process',
  description: `By calling the template you have created each time you create a new user, you can construct your user's multichain presence based on legal jurisdictions or membership tier`,
  buttonLabel: 'Learn More',
  imgStart: false,
 prebutton: false,
  img: '/images/multichain.svg',
  alt: 'Paper',
  dark: false,
  primary: false,
  darkText: false
};
export const homeObj9 = {
  id: 'pos',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'How it Works?',
  headline: 'Trigger SCA function via API calls',
  url: 'https://litepaper.adappter.xyz/process',
  description: `Trigger only functions defined in your Smart Contract Templates to ensure clients operate within the scope of compliant activities in a verifiable manner.`,
  buttonLabel: 'Learn More',
  imgStart: false,
 prebutton: false,
  img: '/images/3.svg',
  alt: 'Paper',
  dark: false,
  primary: false,
  darkText: false
};
export const homeObj10 = {
  id: 'pos',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'How it Works?',
  headline: 'Signing Transactions',
  url: 'https://litepaper.adappter.xyz/process',
  description: ` Each time a user attempts to perform onchain transactions, the relayer will verify author before signing transactions by verifying against existing login session IDs.`,
  buttonLabel: 'Learn More',
  imgStart: false,
 prebutton: false,
  img: '/images/Vf.svg',
  alt: 'Paper',
  dark: false,
  primary: false,
  darkText: false
};

export const homeObj11 = {
  id: 'pos',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'How it Works?',
  headline: 'Gas Fee Aggregation',
  url: 'https://litepaper.adappter.xyz/process',
  description: `Gas fees are managed directly via Adappter's vault to offset the need to manage different types of gas fees across different chains.`,
  buttonLabel: 'Learn More',
  imgStart: false,
 prebutton: false,
  img: '/images/img1.svg',
  alt: 'Paper',
  dark: false,
  primary: false,
  darkText: false
};
export const contact = {
  id: 'contact',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Let us help',
  headline: 'Get started',
  description: 'Improve real-time coordination and accountability through blockchain solutions',
  url: 'https://app.adappter.xyz',
  buttonLabel: 'Get Started',
  imgStart: false,
  prebutton: true,
  img: '/images/2.svg',
  loss:'contact',
  alt: 'about',
  dark: false,
  primary: true,
  darkText: false
};
