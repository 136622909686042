import React, { useEffect, useRef } from 'react';
import * as ChainIcons from "@thirdweb-dev/chain-icons";
import { IconContext } from 'react-icons';

function Multichain() {
  
  const networks = [
    { "id": "ethereum", "label": "Ethereum", "icon": "Ethereum", "language":"solidity" },
    { "id": "bsc", "label": "Binance Smart Chain", "icon": "BinanceCoin", "language":"solidity" },
    { "id": "polygon", "label": "Polygon", "icon": "Polygon", "language":"solidity" },
    { "id": "base", "label": "Base", "icon": "Base", "language":"solidity" },
    { "id": "avax", "label": "Avalache", "icon": "Avalanche", "language":"solidity" },
    { "id": "bitkub", "label": "Bitkub", "icon": "Bitkub", "language":"solidity" },
    { "id": "hedera", "label": "Hedera", "icon": "Hedera", "language":"solidity" },
    { "id": "monad", "label": "Monad", "icon": "Monad", "language":"solidity" },
    { "id": "conflux", "label": "Conflux", "icon": "Conflux", "language":"solidity" },
    { "id": "sui", "label": "Sui", "icon": "Sui", "language":"move" },
    { "id": "ton", "label": "TON", "icon": "Ton", "language":"c++" },
    { "id": "arch", "label": "Arch", "icon": "Arch", "language":"rust" },
    { "id": "akash", "label": "Akash", "icon": "AkashNetwork", "language":"rust" },
    { "id": "solana", "label": "Solana", "icon": "Solana", "language":"rust" },  
    { "id": "nibiru", "label": "Nibiru", "icon": "Nibiru", "language":"rust" },
    { "id": "aura", "label": "Aura", "icon": "Auranw", "language":"rust" }
  ]
  
  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    const scrollSpeed = 1; // Adjust the scroll speed as needed

    const scrollAnimation = () => {
      if (scrollContainer) {
        scrollContainer.scrollLeft += scrollSpeed;
        if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
          scrollContainer.scrollLeft = 0;
        }
      }
      requestAnimationFrame(scrollAnimation);
    };

    scrollAnimation();
  }, []);

  const IComponent = ({ value }) => {
    const IconComponent = ChainIcons[value] || (() => <img src={`/images/logos/${value}.svg`} height="25" alt={value} />);
  
    return (
      <div className="icon-container">
        <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
          {IconComponent && <IconComponent height={25} />}
        </IconContext.Provider>
      </div>
    );
  };

  return (
    <div className="scroll-wrapper">
      <div className="scroll-container" ref={scrollRef}>
        {networks.concat(networks).map((network, index) => (
          <div key={index} className="network-item">
            <IComponent value={network.icon} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Multichain;
